import { Card, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { PropTypes } from "prop-types";

const RewardsForm = ({ data }) => {
  return (
    <Card>
      <TextField
        autoFocus
        required
        margin="dense"
        id="range"
        name="range"
        defaultValue={data?.range}
        label="Range"
        type="text"
        fullWidth
        variant="standard"
        sx={{ marginBottom: "20px" }}
      />
      <TextField
        autoFocus
        required
        margin="dense"
        id="rule"
        name="rule"
        defaultValue={data.rule}
        label="Rule"
        type="text"
        fullWidth
        variant="standard"
      />
      <TextField
        autoFocus
        required
        margin="dense"
        id="type"
        name="type"
        defaultValue={data.type}
        label="Type"
        type="text"
        fullWidth
        variant="standard"
      />
      <TextField
        autoFocus
        required
        margin="dense"
        id="salary"
        name="salary"
        defaultValue={data.salary}
        label="salary"
        type="text"
        fullWidth
        variant="standard"
      />

      <TextField
        autoFocus
        required
        margin="dense"
        id="reward"
        defaultValue={data.reward}
        name="reward"
        label="Reward"
        type="text"
        fullWidth
        variant="standard"
      />
      <FormControl fullWidth>
        <InputLabel
          id="status-label"
          sx={{ transform: "translate(0, 1.5px) scale(0.75)", marginLeft: "10px" }}
        >
          Status
        </InputLabel>
        <Select
          labelId="status-label"
          id="status"
          name="status"
          defaultValue={data.status}
          sx={{
            "& .MuiSelect-root": {
              padding: "10px",
            },
          }}
        >
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </Select>
      </FormControl>
    </Card>
  );
};
export default RewardsForm;

RewardsForm.defaultProps = {
  data: {},
  type: "new",
};

RewardsForm.propTypes = {
  data: PropTypes.any,
  type: PropTypes.string,
};
