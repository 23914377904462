/* eslint-disable react/prop-types */
// Next Work Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

import { Icon } from "@mui/material";
import { setDialog } from "context";
import BusinessTransForm from "../form";
import ApiClient from "Services/ApiClient";
import { updateBusinessTransaction } from "Services/endpointes";
import { deleteBusinessTransaction } from "Services/endpointes";
import { toast } from "react-toastify";
import LookUp from "../lookUp";

function Author({ name, id }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {id}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

function Status({ tnxId, status }) {
  if (tnxId === null) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox display="flex" alignItems="center" flexDirection="column" gap="4px">
          <SoftBadge
            variant="gradient"
            badgeContent="payment pending"
            color="warning"
            size="xs"
            container
          />
        </SoftBox>
      </SoftBox>
    );
  } else if (!status) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBadge variant="gradient" badgeContent="Used" color="warning" size="xs" container />
      </SoftBox>
    );
  } else {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox display="flex" flexDirection="column">
          <SoftBadge
            variant="gradient"
            badgeContent="un-used"
            color="success"
            size="xs"
            container
          />
        </SoftBox>
      </SoftBox>
    );
  }
}
const editToken = (id, getAllBusinessTransaction) => async (formData) => {
  try {
    formData.append("id", id);
    console.log(id, formData, formData.get("id"));
    const response = await ApiClient.putData(updateBusinessTransaction, formData);
    toast.success(response.message);
    getAllBusinessTransaction();
  } catch (error) {
    console.error("Error adding Token:", error);
    toast.error(
      error.response?.data?.message ?? "Failed to delete Toeken. Please try again later."
    );
  }
};

const deleteBusinessTrans = (id, getAllBusinessTransaction) => async (form) => {
  try {
    console.log(id);
    const response = await ApiClient.deleteData(deleteBusinessTransaction, id);
    toast.success(response?.message);
    getAllBusinessTransaction();
  } catch (error) {
    console.error("Error deleting Token:", error);
    toast.error(error.response?.data?.message ?? "Failed to delete Token. Please try again later.");
  }
};
const TokenView = {
  columns: [
    { name: "invoice", align: "center" },
    { name: "user", align: "center" },
    { name: "amount", align: "left" },
    { name: "type", align: "center" },
    { name: "status", align: "center" },
    { name: "receipt", align: "center" },
    { name: "actions", align: "center" },
  ],

  rows: (data, dispatch, getAllBusinessTransaction) => {
    return data.map((e) => {
      const dateObject = new Date(e.createdAt);

      const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
      const formattedDate = dateObject.toLocaleDateString("en-GB", options);

      return {
        amount: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.amount}
          </SoftTypography>
        ),
        type: <Author name={e.type} />,
        user: <Author name={e.userId} />,
        token: <Author name={e.id} />,
        invoice: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.invoiceNo}
          </SoftTypography>
        ),
        status: <Status tnxId={e.transactionId} status={e.status} />,
        receipt: (
          <SoftTypography
            component="a"
            href="#"
            variant="caption"
            color="info"
            fontWeight="medium"
            cursor="pointer"
            onClick={() => {
              setDialog(dispatch, [
                {
                  status: "form",
                  message: `VIEW  - ${e.userId}`,
                  action: "Update",
                  children: <LookUp data={e} />,
                },
              ]);
            }}
          >
            <Icon fontSize="small" color="info">
              visibility
            </Icon>
          </SoftTypography>
        ),
        actions: (
          <SoftBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="10px"
            px={1}
            py={0.5}
          >
            <SoftTypography
              component="a"
              href="#"
              variant="caption"
              color="info"
              fontWeight="medium"
              cursor="pointer"
              onClick={() => {
                setDialog(dispatch, [
                  {
                    status: "form",
                    call: editToken(e.id, getAllBusinessTransaction),
                    message: `UPDATE - TOKEN - ${e.id}`,
                    action: "Update",
                    children: <BusinessTransForm data={e} />,
                  },
                ]);
              }}
            >
              <Icon fontSize="small" color="info">
                edit
              </Icon>
            </SoftTypography>
            <SoftTypography
              component="a"
              href="#"
              variant="caption"
              color="error"
              cursor="pointer"
              fontWeight="medium"
              onClick={() => {
                setDialog(dispatch, [
                  {
                    status: "form",
                    route: "",
                    message: `DELETE - CONNECTION - ${e.id}`,
                    action: "Delete",
                    call: deleteBusinessTrans(e.id, getAllBusinessTransaction),
                  },
                ]);
              }}
            >
              <Icon fontSize="small" color="error">
                delete
              </Icon>
            </SoftTypography>
          </SoftBox>
        ),
      };
    });
  },
};

export default TokenView;
