// Next Work Dashboard React components
import SoftBox from "components/SoftBox";

// Next Work Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import { Menu, MenuItem, Grid, Icon, TablePagination } from "@mui/material";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import Table from "examples/Tables/Table";

import ApiClient from "Services/ApiClient";
import { toast } from "react-toastify";
import { useSoftUIController, startLoading, setLoading } from "context";
import React from "react";

import { getRewards } from "Services/endpointes";
import Footer from "examples/Footer";
import { setRewrds } from "context";

import { createReward } from "Services/endpointes";
import RewardsForm from "./form";
import { setDialog } from "context";
import reawrdView from "./data/rewards";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

function Rewards() {
  const [controller, dispatch] = useSoftUIController();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { rewards } = controller;
  const getAllRewards = async () => {
    startLoading(dispatch, true);
    try {
      const response = await ApiClient.getDataWithPagination(getRewards, 0, 100);
      if (response.status == 200) {
        setRewrds(dispatch, response.data);
        toast.success(response?.message);
      } else {
        setDialog(dispatch, [response]);
      }
    } catch (error) {
      setLoading(dispatch, false);
      toast.error(error.response?.data?.message ?? "Oops! Something went wrong, please try later");
    }
  };
  useEffect(() => {
    rewards.length < 1 && getAllRewards();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let memoizedRows = reawrdView.rows(rewards, dispatch, getAllRewards);
  const addRewards = async (formData) => {
    startLoading(dispatch, true);
    try {
      const response = await ApiClient.createData(createReward, formData);
      if (response.status === 200) {
        getAllRewards();
        setDialog(dispatch, [response]);
      }
    } catch (error) {
      toast.error(error ?? "Failed to add source. Please try again later.");
    }
  };
  const [menu, setMenu] = useState(null);
  const openMenu = ({ currentTarget }) => {
    if (menu === currentTarget) {
      closeMenu();
    } else {
      setMenu(currentTarget);
    }
  };
  const closeMenu = () => setMenu(null);
  return (
    <DashboardLayout>
      <DashboardNavbar endpoint={"rewards"} call={getAllRewards} />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <SoftBox display="flex" justifyContent="end" cursor="pointer" mb={2}>
            <SoftButton onClick={openMenu} variant="gradient" color="dark" ml={2}>
              <Icon sx={{ fontWeight: "bold" }} fontSize="large">
                settings
              </Icon>
              <Menu
                id="simple-menu"
                anchorEl={menu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(menu)}
                onClose={closeMenu}
              >
                <MenuItem
                  onClick={() => {
                    closeMenu();
                    setDialog(dispatch, [
                      {
                        call: addRewards,
                        status: "form",
                        message: "CREATE NEW REWARDS",
                        action: "Add New",
                        children: <RewardsForm />,
                      },
                    ]);
                  }}
                >
                  Add Reward
                </MenuItem>
              </Menu>
            </SoftButton>
          </SoftBox>

          {rewards?.length > 0 ? (
            <>
              <Table columns={reawrdView.columns} rows={memoizedRows} />
              <SoftBox mt={2} display="block" width={90}>
                <TablePagination
                  component="span"
                  count={100}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </SoftBox>
            </>
          ) : (
            <SoftBox mt={4}>
              <SoftBox mb={1.5}>
                <Grid container spacing={3}>
                  <Grid item lg={12}>
                    <Grid item container spacing={3}>
                      <Grid item xs={12} xl={12}>
                        <DefaultInfoCard
                          icon="cloud"
                          title={`You Don't have an active connection yet. Add connection to your portfolio and start earning.`}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          )}
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Rewards;
