export const createAddress = "/create-address";
export const createConnections = "/create-connection";
export const getConnectionByUserID = "/get-connection-by-user-id";
export const getConnections = "/get-connections";
export const validateUser = "/validate-user";
export const updateAdmin = "/update-admin";
// Admin Routes

export const updateAddress = "/update-address";
export const rejectWithdrawl = "/reject-withdraw";
export const sourcetype = "/get-source-by-type";
export const getNotifications = "/get-notifications";
export const getAdmins = "/get-admins";
export const deleteUser = "/delete-user";
export const activateStaff = "/activate-staff";
export const getUsersSelfRent = "/get-user-self";
export const deleteConnection = "/delete-connection";

export const getIncomes = "/get-incomes";
export const getIncomeById = "/get-income-by-id";
export const getSupport = "/get-queries";
export const deleteQuerry = "/delete-query";
export const getIncomeByUserId = "/get-income-by-user-id";
export const updateIncome = "/update-income";
export const deleteIncome = "/delete-income";
export const createIncome = "/create-income";
export const updateQuerry = "/update-query";

export const getKyc = "/get-kyc";
export const createKyc = "/create-kyc";
export const getKycById = "/get-kyc-by-id";
export const getKycByUserId = "/get-kyc-by-user-id";
export const updateKyc = "/update-kyc";
export const deleteKyc = "/delete-kyc";

export const getLimitReward = "/get-claims";
export const getPendingWithdraw = "/get-pending-withdraw";
export const createLimitReward = "/create-claims";
export const getLimitRewardById = "/get-user-claims";
export const updateLimitReward = "/update-claim";
export const deleteLimitReward = "/delete-claim";

export const getProducts = "/get-products";
export const createProduct = "/create-product";
export const getPublished = "/get-published";
export const getProductByType = "/get-product-by-type";
export const getProductById = "/get-product-by-id";
export const updateProduct = "/update-product";
export const deleteProduct = "/delete-product";

export const getRents = "/get-rents";
export const getActiveRent = "/get-active-rent";
export const filterRent = "/filter-rent";
export const createRent = "/create-rent";
export const getRentById = "/get-rent-by-id";
export const getRentByUserId = "/get-rent-by-user-id";
export const updateRent = "/update-rent";
export const deleteRent = "/delete-rent";

export const getRewards = "/get-rewards";
export const createReward = "/create-reward";
export const getRewardById = "/get-reward-by-id";
export const updateReward = "/update-reward";
export const deleteReward = "/delete-reward";

export const getRoyalties = "/get-Royality";
export const createRoyality = "/create-royality";
export const getRoyalityById = "/get-royality-by-id";
export const updateRoyality = "/update-royality";
export const deleteRoyality = "/delete-royality";

export const getSources = "/get-sources";
export const createSource = "/create-source";
export const getSourceById = "/get-source-by-id";
export const getSourceByType = "/get-source-by-type";
export const updateSource = "/update-source";
export const deleteSource = "/delete-source";

export const getTds = "/get-tds";
export const getTdsById = "/get-tds-by-id";
export const updateTds = "/update-tds";
export const deleteTds = "/delete-tds";

export const getUsers = "/get-users";
export const login = "/login";
export const adminLogin = "/admin-login";
export const getAdminById = "/get-admin-by-id";
export const activateAccount = "/activate-acc";
export const registerUser = "/register-user";
export const registerNetworkUser = "/register-nextwork-user";
export const activeConnection = "/get-active-connection";
export const allotedConnection = "/get-alloted-connection";
export const inActiveConnection = "/get-inactive-connection";
export const getMembers = "/get-members";
export const registerAdmin = "/register-admin";
export const forgetPassword = "/forget-password";
export const getUserById = "/get-user-by-id";
export const verifyOtp = "/verify-admin-otp";
export const getVerifications = "/get-verifications";
export const resendOtp = "/resend-otp";
export const sendOtp = "/send-admin-otp";

export const updateUser = "/update-user";
export const ifscValidate = "/ifsc-validate";
export const changePassword = "/change-password";
export const updateProfilePhoto = "/update-profile-photo";

export const getUsersTransactions = "/get-user-transactions";
export const purchase = "/purchase";
export const createTransaction = "/create-transaction";
export const getTransactionById = "/get-transaction-by-id";
export const getTransactionsByUserId = "/get-transactions-by-user-id";
export const updateTransactions = "/update-transactions";
export const getTransactionByType = "/get-transaction-by-type";
export const filterTransactions = "/filter-transactions";
export const deleteUserTrans = "/delete-user-trans";

export const AddRoyality = "/add-royality";
export const AddConsultation = "/add-consultations";
export const AddSources = "/add-source";
export const AddProducts = "/add-product";
export const getBusinessTransaction = "/get-business-transaction";
export const createBusinessTransaction = "/create-business-transaction";
export const updateBusinessTransaction = "/update-business-transaction";
export const deleteBusinessTransaction = "/delete-business-transaction";

export const getConsultationByType = "/get-consultation-by-type";
export const createConsultation = "/create-consultation";
export const updateConsultation = "/update-consultation";
export const deleteConsultation = "/delete-consultation";

export const getAllRoute = "/get-routes";
export const createRoute = "/create-route";
export const updateRoute = "/update-route";
export const deleteRoute = "/delete-route";
export const rejectVerification = "/delete-kyc";
