/* eslint-disable react/prop-types */
// Next Work Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

import { Icon } from "@mui/material";
import { useState } from "react";
import { setDialog } from "context";
import { useSoftUIController } from "context";
import { updateTransactions } from "Services/endpointes";
import ApiClient from "Services/ApiClient";
import { toast } from "react-toastify";
import UserTransactionForm from "../form";
import { deleteUserTrans } from "Services/endpointes";

function Author({ name, id }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {id}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}
function Dates({ created, updated }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          C at : {created}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          U at :{updated}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

function Status({ tnxId, status }) {
  if (!status) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBadge variant="gradient" badgeContent="Failed" color="warning" size="xs" container />
      </SoftBox>
    );
  } else {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBadge variant="gradient" badgeContent="Success" color="success" size="xs" container />
      </SoftBox>
    );
  }
}

const editTransaction = (id, getAllTransactions) => async (formData) => {
  try {
    formData.append("id", id);
    console.log(id, formData, formData.get("id"));
    const response = await ApiClient.putData(updateTransactions, formData);
    toast.success(response.message);
    getAllTransactions();
  } catch (error) {
    console.error("Error updating ", error);
    toast.error(
      error.response?.data?.message ?? "Failed to update Trasnsaction. Please try again later."
    );
  }
};

const deleteRoyalitys = (id, getAllTransactions) => async (form) => {
  try {
    console.log(id);
    const response = await ApiClient.deleteData(deleteUserTrans, id);
    toast.success(response?.message);
    getAllTransactions();
  } catch (error) {
    console.error("Error deleting transaction:", error);
    toast.error(
      error.response?.data?.message ?? "Failed to delete transaction. Please try again later."
    );
  }
};
const TransactionsView = {
  columns: [
    { name: "invoice", align: "center" },
    { name: "user", align: "center" },
    { name: "amount", align: "left" },
    { name: "type", align: "center" },
    { name: "status", align: "center" },
    { name: "paymentMethod", align: "center" },
    { name: "dates", align: "center" },
    { name: "receipt", align: "center" },
    { name: "actions", align: "center" },
  ],

  rows: (data, dispatch, getAllTransactions) => {
    return data.map((e) => {
      const dateObject = new Date(e.createdAt);
      const updatedDateObject = new Date(e.updatedAt);

      const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
      const createdAt = dateObject.toLocaleDateString("en-GB", options);
      const updatedAt = updatedDateObject.toLocaleDateString("en-GB", options);

      return {
        invoice: (
          <SoftTypography color="secondary" fontWeight="medium">
            {e.invoiceNo}
          </SoftTypography>
        ),
        user: <Author name={e.userId} id={e.id} />,
        amount: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.amount}
          </SoftTypography>
        ),
        type: <Author name={e.type} />,
        paymentMethod:
          e.paymentMethod === "tnxId" ? (
            <Author name={e.paymentMethod} id={e.tnxId} />
          ) : (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
              {e.paymentMethod}
            </SoftTypography>
          ),

        status: <Status status={e.status} />,
        dates: <Dates created={createdAt} updated={updatedAt} />,
        receipt: (
          <SoftTypography
            component="a"
            href="#"
            variant="caption"
            color="info"
            fontWeight="medium"
            cursor="pointer"
            onClick={() => {}}
          >
            <Icon fontSize="small" color="info">
              visibility
            </Icon>
          </SoftTypography>
        ),
        actions: (
          <SoftBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="10px"
            px={1}
            py={0.5}
          >
            <SoftTypography
              component="a"
              href="#"
              variant="caption"
              color="info"
              fontWeight="medium"
              cursor="pointer"
              onClick={() => {
                setDialog(dispatch, [
                  {
                    status: "form",
                    call: editTransaction(e.id, getAllTransactions),
                    message: `UPDATE - TRANSACTION - ${e.id}`,
                    action: "Update",
                    children: <UserTransactionForm data={e} />,
                  },
                ]);
              }}
            >
              <Icon fontSize="small" color="info">
                edit
              </Icon>
            </SoftTypography>
            <SoftTypography
              component="a"
              href="#"
              variant="caption"
              color="error"
              cursor="pointer"
              fontWeight="medium"
              onClick={() => {
                setDialog(dispatch, [
                  {
                    status: "form",
                    call: deleteRoyalitys(e.id, getAllTransactions),
                    route: "",
                    message: `DELETE - CONNECTION - ${e.id}`,
                    action: "Delete",
                  },
                ]);
              }}
            >
              <Icon fontSize="small" color="error">
                delete
              </Icon>
            </SoftTypography>
          </SoftBox>
        ),
      };
    });
  },
};

export default TransactionsView;
