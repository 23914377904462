import { Card, TextField } from "@mui/material";
import PropTypes from "prop-types";

const LookUp = ({ data }) => {
  const handleClickImage = () => {
    // Create a blob object from the base64 data URI
    const byteCharacters = atob(data.receipt.file);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: data.receipt.type });

    // Create a temporary URL for the blob
    const imageUrl = URL.createObjectURL(blob);

    // Open the image preview in a new tab
    window.open(imageUrl);
  };

  return (
    <Card>
      <TextField
        autoFocus
        required
        disabled
        margin="dense"
        id="name"
        name="name"
        defaultValue={data.userId}
        label="Name"
        type="text"
        fullWidth
        variant="standard"
      />
      <TextField
        margin="dense"
        id="tokenId"
        name="tokenId"
        defaultValue={data.id}
        label="Token"
        type="text"
        fullWidth
        variant="standard"
        InputProps={{
          readOnly: true,
        }}
      />
      <img
        src={`data:${data.receipt.type};base64,${data.receipt.file}`}
        alt="Receipt Image"
        style={{
          width: "50%",
          height: "100px",
          filter: "blur(1px)",
          marginTop: 8,
          cursor: "pointer",
        }}
        onClick={handleClickImage}
      />
    </Card>
  );
};

LookUp.defaultProps = {
  data: {},
};

LookUp.propTypes = {
  data: PropTypes.shape({
    userId: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    receipt: PropTypes.shape({
      file: PropTypes.string,
      type: PropTypes.string,
    }),
    id: PropTypes.string,
  }),
};

export default LookUp;
