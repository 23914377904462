import { Card, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { PropTypes } from "prop-types";

const RoyaltyForm = ({ data }) => {
  return (
    <Card>
      <TextField
        autoFocus
        required
        margin="dense"
        id="fullName"
        name="fullName"
        defaultValue={data.fullName}
        label="fullName"
        type="text"
        fullWidth
        variant="standard"
      />
      <TextField
        autoFocus
        required
        margin="dense"
        id="phone"
        name="phone"
        defaultValue={data.phone}
        label="phone"
        type="text"
        fullWidth
        variant="standard"
      />
      <TextField
        autoFocus
        required
        margin="dense"
        id="query"
        name="query"
        defaultValue={data.query}
        label="query"
        type="text"
        fullWidth
        variant="standard"
      />

      <FormControl fullWidth>
        <InputLabel
          id="status-label"
          sx={{ transform: "translate(0, 1.5px) scale(0.75)", marginLeft: "10px" }}
        >
          Status
        </InputLabel>
        <Select
          labelId="status-label"
          id="status"
          name="status"
          defaultValue={data.status ?? "viewed"}
          sx={{
            "& .MuiSelect-root": {
              padding: "10px",
            },
          }}
        >
          <MenuItem value={"resolved"}>Resolved</MenuItem>
          <MenuItem value={"viewed"}>In Progress</MenuItem>
        </Select>
      </FormControl>
    </Card>
  );
};

export default RoyaltyForm;

RoyaltyForm.defaultProps = {
  data: {},
  type: "new",
};

RoyaltyForm.propTypes = {
  data: PropTypes.any,
  type: PropTypes.string,
};
