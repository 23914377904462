// @mui material components
import Card from "@mui/material/Card";

// Next Work Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Next Work Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import { Grid, Icon, Menu, MenuItem } from "@mui/material";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import { useEffect, useMemo, useState } from "react";
import { useSoftUIController, setLoading } from "context";
import Table from "examples/Tables/Table";
import SourcesView from "layouts/sources/data/sources";
import ApiClient from "Services/ApiClient";
import { toast } from "react-toastify";
import { getSources } from "Services/endpointes";
import { setSources } from "context";
import SourceForm from "./form";
import { createSource } from "Services/endpointes";
import { startLoading } from "context";
import Footer from "examples/Footer";
import { setDialog } from "context";
import SoftButton from "components/SoftButton";

function Sources() {
  const [controller, dispatch] = useSoftUIController();
  const { sources, user } = controller;

  const getAllSources = async () => {
    setLoading(dispatch, true);
    try {
      const response = await ApiClient.getData(getSources);
      if (response?.status === 200) {
        setSources(dispatch, response.data);
        toast.success(response?.message);
      }
      setLoading(dispatch, false);
    } catch (error) {
      setLoading(dispatch, false);
      console.log(error);
      toast.error(error.response?.data?.message ?? "Oops! Something went wrong, please try later");
    }
  };
  const memoizedRows = SourcesView.rows(sources, dispatch, getAllSources);
  const addSource = async (formData) => {
    startLoading(dispatch, true);
    try {
      const response = await ApiClient.createData(createSource, formData);
      if (response.status === 200) {
        getAllSources();
        setDialog(dispatch, [response]);
      } else {
        setDialog(dispatch, [response]);
      }
    } catch (error) {
      setDialog(dispatch, [error?.response?.data]);
      // toast.error(error?."Failed to add source. Please try again later.");
    }
  };
  const [menu, setMenu] = useState(null);
  const openMenu = ({ currentTarget }) => {
    if (menu === currentTarget) {
      closeMenu();
    } else {
      setMenu(currentTarget);
    }
  };
  const closeMenu = () => setMenu(null);
  useEffect(() => {
    sources.length < 1 && getAllSources();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar endpoint={"sources"} call={getAllSources} />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <SoftBox display="flex" justifyContent="end" cursor="pointer" mb={2}>
            <SoftButton onClick={openMenu} variant="gradient" color="dark" ml={2}>
              <Icon sx={{ fontWeight: "bold" }} fontSize="large">
                settings
              </Icon>
              <Menu
                id="simple-menu"
                anchorEl={menu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(menu)}
                onClose={closeMenu}
              >
                <MenuItem
                  onClick={() => {
                    closeMenu();
                    setDialog(dispatch, [
                      {
                        call: addSource,
                        status: "form",
                        message: "CREATE NEW SOURCE",
                        action: "Add New",
                        children: <SourceForm />,
                      },
                    ]);
                  }}
                >
                  Add Source
                </MenuItem>
              </Menu>
            </SoftButton>
          </SoftBox>

          {/* <SoftBox display="flex" alignItems="center" justifyContent="space-between" p={3}>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="12%"
              >
                <SoftTypography variant="h6">All Sources</SoftTypography>
                <SoftButton
                  variant="outlined"
                  color="success"
                  size="small"
                  iconOnly
                  circular
                  onClick={() => getAllSources()}
                >
                  <Icon sx={{ fontWeight: "bold" }}>refresh</Icon>
                </SoftButton>
              </SoftBox>

              <SoftButton
                variant="gradient"
                color="dark"
                ml={2}
                onClick={() => {
                  setDialog(dispatch, [
                    {
                      call: addSource,
                      status: "form",
                      message: "CREATE NEW SOURCE",
                      action: "Add New",
                      children: <SourceForm />,
                    },
                  ]);
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp;
              </SoftButton>
            </SoftBox> */}
          {sources.length > 0 ? (
            <Table columns={SourcesView.columns} rows={memoizedRows} />
          ) : (
            <SoftBox mt={4}>
              <SoftBox mb={1.5}>
                <Grid container spacing={3}>
                  <Grid item lg={12}>
                    <Grid item container spacing={3}>
                      <Grid item xs={12} xl={12}>
                        <DefaultInfoCard
                          icon="cloud"
                          title={`No Sources Found! Might be network issue or suspicious activity.`}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          )}
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Sources;
