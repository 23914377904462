function formatDate(dateString) {
  const dateObject = new Date(dateString);
  const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
  return dateObject.toLocaleDateString("en-GB", options);
}

class RentModel {
  constructor(
    id,
    userId,
    fullName,
    level,
    storage,
    amount,
    connectionId,
    status,
    endDate,
    createdAt,
    updatedAt
  ) {
    this.id = id;
    this.userId = userId;
    this.fullName = fullName;
    this.level = level;
    this.storage = storage;
    this.amount = amount;
    this.connectionId = connectionId;
    this.status = status;
    this.endDate = formatDate(endDate);
    this.createdAt = formatDate(createdAt);
    this.updatedAt = formatDate(updatedAt);
  }

  fromJson(jsonData) {
    return new RentModel(
      jsonData._id ?? null,
      jsonData.userId,
      jsonData.fullName,
      jsonData.level,
      jsonData.storage,
      jsonData.amount,
      jsonData.connectionId,
      jsonData.status,
      jsonData.endDate,
      jsonData.createdAt ?? new Date(),
      jsonData.updatedAt ?? new Date()
    );
  }

  fromArray(jsonData) {
    return jsonData.map((json) => {
      return new RentModel(
        json._id ?? null,
        json.userId,
        json.fullName,
        json.level,
        json.storage,
        json.amount,
        json.connectionId,
        json.status,
        json.endDate,
        json.createdAt ?? new Date(),
        json.updatedAt ?? new Date()
      );
    });
  }
}

export default RentModel;
