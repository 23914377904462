class BusinessTrans {
  constructor(id, userId, amount, type, invoiceNo, status, receipt) {
    this.id = id;
    this.userId = userId;
    this.amount = amount;
    this.type = type;
    this.invoiceNo = invoiceNo;
    this.status = status;
    this.receipt = receipt;
  }

  fromJson(jsonData) {
    return new BusinessTrans(
      jsonData._id ?? null,
      jsonData.userId ?? null,
      jsonData.amount ?? 0,
      jsonData.type ?? "",
      jsonData.invoiceNo ?? 0,
      jsonData.status ?? false,
      jsonData.receipt ?? { originalName: "", mimetype: "", buffer: "" }
    );
  }

  fromArray(jsonData) {
    if (!Array.isArray(jsonData)) {
      console.error("jsonData is not an array.");
      return [];
    }

    let data = [];
    for (let json of jsonData) {
      data.push(
        new BusinessTrans(
          json._id ?? null,
          json.userId ?? null,
          json.amount ?? 0,
          json.type ?? "",
          json.invoiceNo ?? 0,
          json.status ?? false,
          json.receipt ?? { originalName: "", mimetype: "", buffer: "" }
        )
      );
    }
    return data;
  }
}

export default BusinessTrans;
