/* eslint-disable react/prop-types */
// Next Work Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

import { Icon } from "@mui/material";
import { setDialog } from "context";
import AppAccessForm from "../form";

function Author({ name, id }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {id}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

function Status({ status }) {
  if (!status) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBadge variant="gradient" badgeContent="Inactive" color="warning" size="xs" container />
      </SoftBox>
    );
  } else {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox display="flex" flexDirection="column">
          <SoftBadge variant="gradient" badgeContent="Active" color="success" size="xs" container />
        </SoftBox>
      </SoftBox>
    );
  }
}

function Verified({ status }) {
  if (!status) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBadge variant="gradient" badgeContent="false" color="error" size="xs" container />
      </SoftBox>
    );
  } else {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox display="flex" flexDirection="column">
          <SoftBadge variant="gradient" badgeContent="true" color="success" size="xs" container />
        </SoftBox>
      </SoftBox>
    );
  }
}

const RoutesView = {
  columns: [
    { name: "id", align: "left" },
    { name: "index", align: "left" },
    { name: "name", align: "left" },
    { name: "key", align: "left" },
    { name: "type", align: "center" },
    { name: "route", align: "center" },
    { name: "auth", align: "center" },
    { name: "status", align: "center" },
    { name: "nocollapse", align: "center" },
    { name: "actions", align: "center" },
  ],
  rows: (data, dispatch, getAllRoutes) => {
    data.sort((a, b) => a.index - b.index);
    return data.map((e) => {
      const dateObject = new Date(e.createdAt);

      const options = { day: "2-digit", month: "2-digit", year: "2-digit" };

      return {
        id: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.id}
          </SoftTypography>
        ),
        index: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            Index - {e.index}
          </SoftTypography>
        ),
        name: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.name}
          </SoftTypography>
        ),
        key: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.key}
          </SoftTypography>
        ),
        type: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.type}
          </SoftTypography>
        ),
        route: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.route}
          </SoftTypography>
        ),
        auth: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.auth}
          </SoftTypography>
        ),
        status: <Status status={e.status} />,
        nocollapse: <Verified status={e.noCollapse} />,

        actions: (
          <SoftBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="10px"
            px={1}
            py={0.5}
          >
            <SoftTypography
              component="a"
              href="#"
              variant="caption"
              color="info"
              fontWeight="medium"
              cursor="pointer"
              onClick={() => {
                setDialog(dispatch, [
                  {
                    status: "form",
                    route: "",
                    message: `UPDATE - ROUTE - ${e.id}`,
                    action: "Update",
                    children: <AppAccessForm data={e} />,
                  },
                ]);
              }}
            >
              <Icon fontSize="small" color="info">
                edit
              </Icon>
            </SoftTypography>
            <SoftTypography
              component="a"
              href="#"
              variant="caption"
              color="error"
              cursor="pointer"
              fontWeight="medium"
              onClick={() => {
                setDialog(dispatch, [
                  {
                    status: "form",
                    route: "",
                    message: `DELETE - CONNECTION - ${e._id}`,
                    children: (
                      <p style={{ color: "red", padding: "10px 0" }}>
                        Please confirm before deleting the Route. It can create bugs.
                      </p>
                    ),
                    action: "Delete",
                  },
                ]);
              }}
            >
              <Icon fontSize="small" color="error">
                delete
              </Icon>
            </SoftTypography>
          </SoftBox>
        ),
      };
    });
  },
};

export default RoutesView;
