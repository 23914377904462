import { Card, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { PropTypes } from "prop-types";

const BusinessTransForm = ({ data }) => {
  return (
    <Card>
      <TextField
        autoFocus
        required
        margin="dense"
        id="userId"
        name="userId"
        defaultValue={data.userId}
        label="userId"
        type="text"
        fullWidth
        variant="standard"
      />
      <TextField
        autoFocus
        required
        margin="dense"
        id="amount"
        name="amount"
        defaultValue={data.amount}
        label="Amount"
        type="text"
        fullWidth
        variant="standard"
      />
      <TextField
        autoFocus
        required
        margin="dense"
        id="type"
        name="type"
        defaultValue={data.type}
        label="Type"
        type="text"
        fullWidth
        variant="standard"
      />
      {!data?.receipt && (
        <TextField
          autoFocus
          required
          margin="dense"
          id="file"
          name="receipt"
          label="receipt"
          type="file"
          fullWidth
          variant="standard"
        />
      )}

      <FormControl fullWidth>
        <InputLabel
          id="status-label"
          sx={{ transform: "translate(0, 1.5px) scale(0.75)", marginLeft: "10px" }}
        >
          Status
        </InputLabel>
        <Select
          labelId="status-label"
          id="status"
          name="status"
          defaultValue={data.status}
          sx={{
            "& .MuiSelect-root": {
              padding: "10px",
            },
          }}
        >
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </Select>
      </FormControl>
    </Card>
  );
};
export default BusinessTransForm;

BusinessTransForm.defaultProps = {
  data: {},
  type: "new",
};

BusinessTransForm.propTypes = {
  data: PropTypes.any,
  type: PropTypes.string,
};
