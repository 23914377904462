/* eslint-disable react/prop-types */
import React from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import { Icon } from "@mui/material";
import { setDialog } from "context";
import { deleteRoyality } from "Services/endpointes";
import { toast } from "react-toastify";
import ApiClient from "Services/ApiClient";
import { updateRoyality } from "Services/endpointes";
import RoyaltyForm from "../form";
import SupportForm from "../form";
import { updateQuerry } from "Services/endpointes";
import { deleteQuerry } from "Services/endpointes";

const editRoyality = (id, getRoyality) => async (formData) => {
  try {
    formData.append("id", id);
    console.log(id, formData, formData.get("id"));
    const response = await ApiClient.putData(updateQuerry, formData);
    toast.success(response.message);
    getRoyality();
  } catch (error) {
    console.error("Error adding Royality:", error);
    toast.error(
      error.response?.data?.message ?? "Failed to delete Royality. Please try again later."
    );
  }
};
function Status({ status }) {
  if (status === "unviewed") {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBadge variant="gradient" badgeContent="Pending" color="warning" size="xs" container />
      </SoftBox>
    );
  }
  if (status === "viewed") {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBadge
          variant="gradient"
          badgeContent="In Progress"
          color="warning"
          size="xs"
          container
        />
      </SoftBox>
    );
  } else {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox display="flex" flexDirection="column">
          <SoftBadge variant="gradient" badgeContent="Closed" color="success" size="xs" container />
        </SoftBox>
      </SoftBox>
    );
  }
}
const deleteRoyalitys = (id, getAllRoyality) => async (form) => {
  try {
    console.log(id);
    const response = await ApiClient.deleteData(deleteQuerry, id);
    toast.success(response?.message);
    getAllRoyality();
  } catch (error) {
    console.error("Error deleting royality:", error);
    toast.error(
      error.response?.data?.message ?? "Failed to delete royality. Please try again later."
    );
  }
};
const supportView = {
  columns: [
    { name: "id", align: "left" },
    { name: "name", align: "left" },
    { name: "phone", align: "left" },
    { name: "querry", align: "left" },
    { name: "date", align: "center" },
    { name: "updated", align: "center" },
    { name: "status", align: "center" },
    { name: "actions", align: "center" },
  ],

  rows: (data, dispatch, getAllRoyality) => {
    return data?.map((e) => {
      console.log(e, "sdgvfdsjgvkjsdgvsajdbfjsadcvuysjxgncujksgbcvyusg");
      const dateObject = new Date(e.createdAt);

      const updateddateObject = new Date(e.updatedAt);

      const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
      const formattedDate = dateObject.toLocaleDateString("en-GB", options);
      const updatedformattedDate = updateddateObject.toLocaleDateString("en-GB", options);

      return {
        id: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.userId}
          </SoftTypography>
        ),
        name: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.fullName}
          </SoftTypography>
        ),
        phone: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.phone}
          </SoftTypography>
        ),
        querry: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.query}
          </SoftTypography>
        ),
        date: formattedDate,
        updated: updatedformattedDate,
        status: <Status status={e.status} />,
        actions: (
          <SoftBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="4px"
            px={1}
            py={0.5}
          >
            <SoftTypography
              component="a"
              href="#"
              variant="caption"
              color="info"
              fontWeight="medium"
              cursor="pointer"
              onClick={() => {
                setDialog(dispatch, [
                  {
                    status: "form",
                    call: editRoyality(e.id, getAllRoyality),
                    action: "Update",
                    message: `UPDATE - ${e.type}
                    `,
                    children: <SupportForm data={e} />,
                  },
                ]);
              }}
            >
              <Icon fontSize="small" color="info">
                edit
              </Icon>
            </SoftTypography>
            <SoftTypography
              component="a"
              href="#"
              variant="caption"
              color="error"
              cursor="pointer"
              fontWeight="medium"
              onClick={() => {
                setDialog(dispatch, [
                  {
                    status: "form",
                    call: deleteRoyalitys(e.id, getAllRoyality),
                    action: "Delete",
                    message: `DELETE - ${e.type}`,
                  },
                ]);
              }}
            >
              <Icon fontSize="small" color="error">
                delete
              </Icon>
            </SoftTypography>
          </SoftBox>
        ),
      };
    });
  },
};

export default supportView;
