class SupportModel {
  constructor(id, userId, phone, query, status, fullName, createdAt, updatedAt) {
    this.id = id;
    this.userId = userId;
    this.phone = phone;
    this.query = query;
    this.status = status;
    this.fullName = fullName;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  fromJson(jsonData) {
    return new SupportModel(
      jsonData._id ?? null,
      jsonData.userId ?? "",
      jsonData.phone ?? "",
      jsonData.query ?? "",
      jsonData.status ?? "unviewed",
      jsonData.fullName ?? "",
      jsonData.createdAt ?? new Date(),
      jsonData.updatedAt ?? new Date()
    );
  }

  fromArray(jsonData) {
    if (!Array.isArray(jsonData)) {
      console.error("jsonData is not an array.");
      return [];
    }

    let data = [];
    for (let json of jsonData) {
      data.push(
        new SupportModel(
          json.id ?? null,
          json.userId ?? null,
          json.phone ?? 0,
          json.query ?? "",
          json.status ?? "unviewed",
          json.fullName ?? "",
          jsonData.createdAt ?? new Date(),
          jsonData.updatedAt ?? new Date()
        )
      );
    }
    return data;
  }
}

export default SupportModel;
